.top-nav{
    background-color: #E5E5E5;
}
.nav-link:hover,
.nav-link:focus {
    color: blue;
    text-decoration: underline solid black 3px;
}

.nav-link {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #666666;
    position: sticky;
}

.sub-nav {
    height: 54px;
    background: #ebebeb;
}

.user-name{
    font-size: 20px !important;
}
.user-role{
    font-size: 14px;
}