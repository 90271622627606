.filter-button-div{
    width: 105px;
    height: 40px;
    border: 1.2px solid #45464F;
    background-color: #FEFEFE;
    border-radius: 30px;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0 0 2px #45464F;
}
.dropdown-content{
    cursor: pointer;
    margin-top: 10px;
    width: fit-content !important;
    position: absolute;
    top: 100%;
    z-index: 2;
    white-space: nowrap !important;
    margin-left: -70px;
    box-shadow: 0 0 5px gray;
    border-radius: 6px;
}