html,
body,
#root {
  box-sizing: border-box;
  min-height: 100vh;
  font-family: "Nunito Sans", sans-serif !important;
  overflow-x: hidden;
  background-color: #F9F9F9
}

@import './Widgets/SideMenuBar/sideMenuBar.css';
@import './Components/RealTimeMonitoring/realtime_monitoring.css';
@import './Widgets/TopNav/_topNav.css';
@import './Widgets/MenuAndSubMenuNames/menuSubmenuName.css';
@import './Widgets/CustomBadge/customBadge.css';
@import './Components/CustomCard/customcard.css'
