.custom-card{
    width: 380px;
    height: 173px;
    &:hover{
        background-color: #003FA3;
        color: white;
    }
    box-shadow: 0 0 20px #00000029;
}
.card-title{
    font-size: 14px;
    font-weight: bold;
}
.card-description{
    margin-top: 13px;
    min-height: 50px;
    font-size: 12px;
    font-weight: 400;
}
.card-footer-row{
    font-size: 12px;
}