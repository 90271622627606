.container{
    max-width: 100% !important;
    overflow-x: hidden;
    min-height: 100vh !important;
}
.heading-text{
    font-size: 20px !important;
    font-weight: 400 !important;

}
.add-new-button{
    text-align: center;
    padding-top: 8px;
    width: 181px;
    height: 40px;
    border-radius:100px ;
    background-color: #003FA3;
    color: white;
    font-size: 16px;
    cursor: pointer;
}
.add-new-button-container {
    margin-right: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }