/* Badge Style */
.custom-badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 2px 8px 2px 6px; 
    border-radius: 16px; 
    height: 30px !important;
    width: fit-content;
    box-shadow: 0 0 5px rgb(194, 193, 193);
  }
  
  /* Icon Style */
  .custom-badge .badge-icon {
    font-size: 25px; 
  }
  
  /* Badge Text Style */
  .custom-badge .badge-text {
    font-size: 14px; 
    font-weight: bold;
    text-align: center;
  }

  .only-badge{
    width: 40px;
    height: 30px !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  