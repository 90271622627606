 
  body {
    background-color: #f5f5fa;
  }
  
  .login-screen-background-image {
    background-size: cover;
    background-image: url("../Assets/login-background-image.png");
    height: 100vh;
  }
  
  .login-card {
    margin-top: 4rem;
    margin-left: auto;
    max-width: 410px;
  }
  
  .login-form {
    margin-top: 53px;
  }
  
  .password-field {
    margin-top: 20px;
  }
  
  .nsp-line {
    width: 173px;
    height: 26px;
    border-radius: 20px;
    background: #F00;
  }
  
  .nsp-circle {
    width: 30px;
    height: 30px;
    background: #F00;
    border-radius: 15px;
    margin-left: 10px;
    margin-top: -3px;
  }
  
  .nsp-envision-text {
    color: rgba(60, 60, 60, 0.80);
    font-size: 10rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -2.2px;
  }
  
  .nsp-insight-text {
    color: #F00;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.64px;
  }
  
  .nsp-data-analytics-text {
    color: #3B3B3B;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.64px;
  }
  
  .right-section {
    margin-left: 45px;
  }
  
  .login-form-input-fields {
    border-radius: 8px !important;
    border: 1px solid #E2E3E8 !important;
  }
  
  /* login screen */
  .font-weight-600 {
    font-weight: 600 !important;
  }
  
  .production-card-font-size {
    font-size: 0.8rem !important;
  }
  
  .position-absolute {
    width: 95vw;
    top: 4%;
  }
  
  .min-height-100 {
    min-height: 100% !important;
  }
  
  .in-center {
    text-align: center;
    align-self: center;
  }
  
  .width-60 {
    max-width: 60%;
  }
  
  .font-weight-normal {
    color: #006fd8;
    font-size: 2em;
    text-align: center;
  }
  
  .text-capitalize {
    color: #424242;
    font-size: 0.9em;
    text-align: center;
  }
  
  .login-text {
    color: #323232;
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .font-weight-bold {
    color: #323232;
    font-size: 24px;
  }
  
  .text-box {
    height: 50px;
    border-radius: 8px;
    margin-top: 10px;
    padding: 16px;
  }
  
  .error {
    border: 1px solid red !important;
  }
  
  .paswrdrec {
    color: #454545 !important;
  }
  
  .login-img-banner {
    width: 55%;
    margin-top: 20%;
  
    border-radius: 16px;
  }
  
  .display-flex-vertical-center {
    display: flex;
    align-items: center;
  }
  
  .flex-vertical-center {
    align-items: center;
  }
  
  .table-tr-cell {
    vertical-align: middle;
    display: table-cell;
  }
  
  .lmw-login-logo {
    height: 80px;
  }
  
  .width-100 {
    width: 100%;
  }
  
  .MuiAppBar-colorPrimary {
    background-color: #006fd8 !important;
  }
  
  .btn-primary {
    background-color: #006fd8 !important;
    color: white !important;
  }
  
  .primary-text-color {
    color: #006fd8 !important;
  }
  
  .primary-btn {
    background-color: #006fd8 !important;
    color: #ffffff !important;
  }
  
  .sign-in-btn {
    background-color: #F00 !important;
    color: #ffffff !important;
    /* margin-bottom: 50px !important; */
    /* box-shadow: 4px 12px 50px rgba(0, 111, 216, 0.5); */
    height: 48px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    border: none !important;
    border-radius: 10px;
  }
  
  .disable-button {
    height: 48px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    border: none !important;
    border-radius: 10px;
  }
  
  .red-btn {
    background-color: #f44336 !important;
    color: #ffffff !important;
    /* margin-bottom: 50px !important; */
    /* box-shadow: 4px 12px 50px rgba(0, 111, 216, 0.5); */
    height: 48px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    border: none !important;
    border-radius: 10px;
  }
  
  .capitalization {
    text-transform: capitalize !important;
  }
  
  
  .chart-icon-btn {
    background-color: #006fd8 !important;
    color: #ffffff !important;
    box-shadow: 4px 12px 50px rgba(0, 111, 216, 0.5);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    border: none !important;
    border-radius: 10px;
  }
  
  .yellow-btn {
    background-color: #ffc700 !important;
    color: #ffffff !important;
    height: 48px;
    padding: 0px;
    border: none !important;
    border-radius: 10px;
  }
  
  .fav-btn {
    .MuiButton-root {
      min-width: 51px !important;
    }
  }
  
  .grey-btn {
    background-color: #eef7ff !important;
    color: #000 !important;
    height: 48px;
    box-shadow: 4px 12px 50px rgba(0, 111, 216, 0.5);
  
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    border: none !important;
    border-radius: 10px;
  }
  
  .report-blue-btn {
    background-color: #006fd8 !important;
    color: #ffffff !important;
    box-shadow: 1px 2px 10px 2px rgba(0, 111, 216, 0.5) !important;
    /* margin-bottom: 50px !important; */
    /* box-shadow: 4px 12px 50px rgba(0, 111, 216, 0.5); */
    height: 48px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    border: none !important;
    border-radius: 10px;
  }
  
  .grey-btn-reports {
    background-color: #eef7ff !important;
    color: #000 !important;
    height: 48px;
    box-shadow: 2px 2px 6px rgba(0, 111, 216, 0.25) !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    border: none !important;
    border-radius: 10px;
  }
  
  .MuiButton-contained.Mui-disabled {
    box-shadow: none !important;
  }
  
  .height-5 {
    height: 5vh;
  }
  
  .min-height-80 {
    min-height: 80vh;
  }
  
  .max-height-80 {
    max-height: 80vh;
  }
  
  .bg-blue-primary {
    background: #ebf7ff !important;
  }
  
  .bg-blue-primary:hover {
    background: #006fd8 !important;
  }
  
  .bg-blue-group-btn {
    background: #006fd8 !important;
    border-radius: 8px !important;
    height: 48px;
  }
  
  .display-flex-justify-center {
    display: flex;
    justify-content: center;
  }
  
  .align-item-baseline {
    align-items: baseline !important;
  }
  
  .justify-content-flex-end {
    justify-content: flex-end !important;
  }
  