.sideMenuBar {
    position: sticky;
    top: 0;
    height: 100%;
    width: fit-content !important;
    background-color: white;
}  
.sideMenuBar__sidebar {
    height: 105vh;
    width: fit-content !important;
    background-color: white;
}
.sideMenuBar__header-toggle {
    width: fit-content !important;
}
.sideMenuBar__bg-menu-icons {
    cursor: pointer;
    font-size: 32px !important;
}
.sideMenuBar__sidemenu-nav-active {
    color: #0055D4 !important;
    font-size: 14px;
    font-weight: 600;
    span svg {
    fill: #0055D4;
    width: fit-content !important;
    cursor: pointer;
    }
}
.sideMenuBar__nav-logo-name {
    cursor: pointer;
    font-size: 14px;
    color: #202124;
    &:hover {
    color: bdarlack;
    text-decoration: none;
    width: fit-content !important;
    }
}
.sideMenuBar__nav-logo-name:hover {
    svg {
    fill: #0055D4;
    }
}
&.sideMenuBar__nav-logo-name-hide {
    display: none;
}
&.sideMenuBar__menubar {
    width: calc(3rem + 222px);
}
&.sideMenuBar__nav-logo-cheveron-icon {
    font-size: 22px;
    cursor: pointer;
}
.menu-row-active{
    background-color: #EEF0FF;
    border-right:4px solid #0055D4;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    width: fit-content !important;
}
.sub-menu-row-active{
    background-color: #b6fa2d;
}
.logout-div{
    cursor: pointer;
    font-size: 14px !important;
}
.menu-container {
    position: relative; 
    width: fit-content !important;
    white-space: nowrap !important;
  }
  
  .submenu-container {
    position: absolute;
    margin-left: 20px;
    top: 0;
    left: 100%; 
  }